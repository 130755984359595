<template>
  <draggable
    class="list-group"
    tag="ul"
    v-model="formsToSort"
    v-bind="dragOptions"
    @start="drag = true"
    @end="drag = false"
    :disabled="disabled"
  >

  <div class="row" v-if="isGroupedFiles">
    <div class="col-12">
      <div class="ml-4 file-type">
        <input
          class="input-file-type"
          type="radio"
          :name="`type_all`"
          :id="`intern_all`"
          value="intern"
          checked
          v-model="type_all"
        />
        <p>Realizado internamente</p>

        <input
          class="input-file-type"
          type="radio"
          :name="`type_all`"
          :id="`intern_all`"
          value="extern"
          v-model="type_all"
        />
        <p>Em outra instituição</p>
      </div>
    </div>
  </div>

    <transition-group type="transition" :name="!drag ? 'flip-list' : null">      
      <FileItem
        v-for="(form, index) in formsToSort"
        :key="`file_item_${index}`"
        :class="{ cursorGrab: !disabled }"
        :index="index"
        :form="form"
        :isLaudable="isLaudable"
        :removeFile="removeFile"
        :responsibles="responsibles"
        :changeName="changeName"
        :procedures="procedures"
        :getResponsables="getResponsables"
        :isGroupedFiles="isGroupedFiles"
        :type_all="type_all"
      />
    </transition-group>
  </draggable>
</template>

<script>
import { getCurrentClinic } from '@/utils/localStorageManager'
export default {
  props: {
    forms: {
      type: Array,
      default: () => []
    },
    isLaudable: {
      type: Function,
      default: () => {}
    },
    removeFile: {
      type: Function,
      default: () => {}
    },
    changeName: {
      type: Function,
      default: () => {}
    },
    isGroupedFiles: Boolean,
    showDistinctType: Boolean
  },
  components: {
    FileItem: () => import('./FileItem'),
    draggable: () => import('vuedraggable'),
  },
  data: () => ({
    clinic: getCurrentClinic(),
    drag: false,
    responsibles: [],
    procedures: [],
    type_all: 'intern'
  }),
  async created() {
    await this.getLaudableProcedures()
  },
  computed: {
    dragOptions() {
      return {
        animation: 200,
        group: 'description',
        disabled: false,
        ghostClass: 'ghost',
      }
    },
    formsToSort: {
      get() {
        return this.forms
      },
      set(value) {
        const items = value.map((el, index) => ({
          ...el,
          position: index
        }))
        const orderedItems = items.sort((a, b) => a.position - b.position)
        this.$emit('update-forms', orderedItems)
      }
    },
    someFormIsLaudable() {
      return this.forms.some(form => form.laudable)
    },
    disabled() {
      return this.someFormIsLaudable || !this.isGroupedFiles
    }
  },
  methods: {
    async getLaudableProcedures() {
      const { data } = await this.api.allLaudableProcedures(this.clinic.id)
      this.procedures = data.map(el => ({
        label: el.item ? el.item.name : el.procedure.name,
        id: el.item ? el.item.id : el.procedure.id
      }))
    },
    async getResponsables(index, procedureId) {
      try {
        const { data } = await this.api.getLaudableProcedureResponsibles(
          procedureId
        )
        const responsibles = data.map(el => ({
          id: el.responsible.person.id,
          name: el.responsible.person.name
        }))
        responsibles.unshift({
          id: null,
          name: 'Qualquer profissional laudador'
        })
        this.forms[index].responsibles = responsibles
      } catch (err) {
        this.$toast.error(err.message)
      }
    }
  },
}
</script>

<style lang="scss" scoped>
.flip-list-move {
  transition: transform 0.5s;
}
.no-move {
  transition: transform 0s;
}
.ghost {
  opacity: 0.5;
  background: #c8ebfb;
}
.list-group {
  min-height: 20px;
}
.cursorGrab {
  cursor: grab !important;
}
.laudable-check{
  display: inline-flex;
}
.input-file-type:checked {
  accent-color: #305bf2 !important;
}
</style>
